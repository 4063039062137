import React, { useContext } from "react";
import { GlobalContext } from '../context/GlobalState';

function Balance() {

    const { transactions } = useContext(GlobalContext);

    const amounts = transactions.map(transaction => transaction.amount);
    const total = amounts.reduce((acc, item) => (acc += item), 0).toFixed(2); //upto 2 decimal places


    return (
        <>
          <h4>Your Balance</h4>
          <h1>Rs{total}</h1>
        </>
    );
}

export default Balance