import React from "react";

function Header() {
    return (
        <header className="header">
           <h2>EXPENSE TRACKER</h2>
        </header>
    );
}

export default Header